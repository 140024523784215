import { Col, Input, Row, Select, InputNumber, DatePicker } from 'antd';
import React from 'react';
import moment from "moment";
import { MaskedInput } from "antd-mask-input";
import { capitalizeFirstLetter, isNumber } from 'utils/GlobalFunctions';
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";
const UiFormSingle = props => {
    const { element, errorField, setErrorField, userData, setUserData, data } = props;



    const getUiElement = () => {
        if (element.ui_type === "1") { //Text
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Input
                    value={data[element.reg_column_name]}
                    placeholder={element.hint_text}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = element.reg_column_name.includes("name") ? capitalizeFirstLetter(
                            e.target.value
                        ) : e.target.value;

                        setUserData({
                            ...tmpData
                        });

                        if (element.reg_column_name.includes("street")) {
                            setUserData({
                                ...userData,
                                bill_address: e.target.value,
                            });
                        } else if (element.reg_column_name.includes("city")) {
                            setUserData({
                                ...userData,
                                bill_city: e.target.value,
                            });
                        } else if (element.reg_column_name.includes("zip")) {
                            setUserData({
                                ...userData,
                                bill_postal_code: e.target.value,
                            });
                        }
                    }}
                />
            </Col>
        } else if (element.ui_type === "2") { //Number
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <InputNumber
                    min={1}
                    placeholder={element.hint_text}
                    size="small"
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = e;

                        if (element.reg_column_name.includes("zip")) {
                            setUserData({
                                ...tmpData,
                                bill_postal_code: e
                            });
                        } else {
                            setUserData({
                                ...tmpData
                            });
                        }
                    }}
                    step={1}
                />
            </Col>
        } else if (element.ui_type === "3") { //Dropdown
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Select
                    placeholder={element.hint_text}
                    value={data[element.reg_column_name] == undefined ? null : data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-select-error`
                            : `common-select`
                    }

                    onSelect={(v) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = v;
                        setUserData({
                            ...tmpData
                        });

                        if (element.reg_column_name.includes("state")) {
                            setUserData({
                                ...userData,
                                bill_state: v,
                            });
                        }
                    }}
                >
                    {
                        element.dropdown_list && element.dropdown_list.split(",").map((dropItem, dropIndex) => {
                            return <Option value={dropItem} key={dropIndex}>{dropItem}</Option>
                        })
                    }
                </Select>
            </Col>
        } else if (element.ui_type === "4") { //Phone Number
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Input
                    type="text"
                    value={data[element.reg_column_name]}
                    placeholder="___-___-____"
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e, w) => {

                        let str = e.target.value;
                        let filStr = str.replace(/[- ]/g, "");
                        let tmpPhone = "";
                        if (filStr == "" || filStr.length == 0) {
                            let tmp0Data = { ...userData };
                            tmp0Data[element.reg_column_name] = "";
                            setUserData({
                                ...tmp0Data
                            });

                            return;
                        }
                        if (!isNumber(filStr)) {
                            return;
                        }
                        if (filStr.length > 10) {
                            return;
                        }

                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }

                        if (filStr.length > 3 && filStr.length < 7) {
                            str = filStr.slice(0, 3) + "-" + filStr.slice(3);
                        }

                        if (filStr.length > 6) {
                            str =
                                filStr.slice(0, 3) +
                                "-" +
                                filStr.slice(3, 6) +
                                "-" +
                                filStr.slice(6);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = str;
                        setUserData({
                            ...tmpData
                        });
                    }}
                />
                {/* <MaskedInput
                    mask={"000-000-0000"}
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = e.target.value;
                        console.log(tmpData)
                        setUserData({
                            ...tmpData
                        });
                    }}
                /> */}
            </Col>
        } else if (element.ui_type === "6") { //Date
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <DatePicker
                    style={{
                        width: "100%",
                        cursor: "pointer",
                    }}
                    format={dateFormat}
                    allowClear={false}
                    value={
                        data[element.reg_column_name] ? moment(data[element.reg_column_name], dateFormat) : ""
                    }
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(v, str) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }

                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = str;
                        setUserData({
                            ...tmpData
                        });
                    }}
                />
            </Col>
        }
    }
    return (
        <>
            {getUiElement()}
        </>
    );
};

export default UiFormSingle;
