import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "navigation/Auth/ProvideAuth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateSubscription, updateUser } from "redux/actions/userActions";
import axios from "axios";
import CommonDivWidget from "components/CommonDivWidget/CommonDivWidget";
import { Steps, Button, message, Row, Col, Spin, BackTop } from "antd";
import CommonButton from "components/ButtonWidgets/CommonButton/CommonButton";
import "./RegisterPage.css";
import {
  getInitData,
  isEmpty,
  isEmptyOneField,
  ValidateEmail,
} from "utils/GlobalFunctions";
import { ROOT, ROUTE_WELCOME } from "navigation/CONSTANTS";
import StepInfo from "./StepInfo/StepInfo";
import StepFee from "./StepFee/StepFee";
import StepCheckoutForte from "./StepCheckoutForte/StepCheckoutForte";

import { apiRegisterFailedPayment, apiRegisterUser } from "services/registerService";
import StepCheckoutStax from "./StepCheckoutStax/StepCheckoutStax";
import StepCheckoutStaxMulti from "./StepCheckoutStax/StepCheckoutStaxMulti";

const { Step } = Steps;

function RegisterPage(props) {
  const userDataStore = useSelector((x) => x.userDataStore);
  const appDataStore = useSelector((x) => x.appDataStore);
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isBusy, setIsBusy] = useState(false);
  const [errorField, setErrorField] = useState([]);
  const [feeValid, setFeeValid] = useState(true);

  const [current, setCurrent] = useState(0);
  const [recordList, setRecordList] = useState([]);
  const [userData, setUserData] = useState({
    camperInfo: [{
      sel_trip: "",
      src_fee: 0,
      real_fee: 0,
      sibling_discount: 0,
      zip_discount: 0,
      zip_include: false,
      sibling_include: false
    }],
    camper_reg_checked: false,
    camper_reg_sel_info: {
      "ID": "",
      "registration_id": "",
      "registration_title": "",
      "registration_text": "",
      "registration_fee": "",
      "registration_sort_order": "",
      "sibling_discount": "",
      "sibling_discount_amount": "",
      "verify_zipcode": "",
      "zipcodes_allowed": "",
      "checked": false
    },
    tripList: [],
    isPayment: false,
    discount_amount: 0,

    // a_first_name: "",
    // a_last_name: "",
    // a_email: "",
    // a_cell_phone: "",
    // a_home_phone: "",
    // a_relation: "",

    // c_first_name: "",
    // c_last_name: "",
    // c_email: "",
    // c_cell_phone: "",

    // a_park_id: "",
    // a_adults: "",
    // a_spouse: "",
    // a_spouse_anwser: "",
    // a_c_first_name: "",
    // a_c_last_name: "",
    // a_c_email: "",
    // a_c_cell_phone: "",

  });
  const [cellPhone, setCellPhone] = useState("___-___-____");
  const [homePhone, setHomePhone] = useState("___-___-____");
  const [aCellPhone, setACellPhone] = useState("___-___-____");
  const [aHomePhone, setAHomePhone] = useState("___-___-____");
  const [contactPhone, setContactPhone] = useState("___-___-____");
  const [acontactPhone, setAContactPhone] = useState("___-___-____");

  const [curDomain, setCurDomain] = useState("")

  const [appInfo, setAppInfo] = useState({
    registration_info: null,
    discount_codes: [],
    group_list: [],
    registration_main_list: [],
    waiver_list: []
  });
  const [isStax, setIsStax] = useState(true);

  const [regType, setRegType] = useState(false);
  const [uiList, setUIList] = useState([]);


  useEffect(() => {
    let tmpDomain = window.location.hostname;
    if (tmpDomain.trim() == "localhost" || tmpDomain.trim() == "192.168.100.81") {
      tmpDomain = "devtuxedocamp.beclutch.club";
    }
    setCurDomain(tmpDomain);
    if (appDataStore.appInfo == undefined) {
      history.push(ROOT);
    } else {
      setAppInfo(appDataStore.appInfo);
      let staxInfo = appDataStore.appInfo.registration_info.merchant;
      setIsStax(staxInfo == "stax");
      setUIList(appDataStore.appInfo.ui_list);
    }
    if (localStorage.getItem("camper_registration_type") == null) {
      message.error("Please choose registration type");
      history.replace(ROOT);
    } else {
      setRegType(localStorage.getItem("camper_registration_type"));
    }

    initGlobalVal();
  }, []);
  const initGlobalVal = () => {
    const step = localStorage.getItem("camper_step");
    if (step != null) {
      setCurrent(parseInt(step));
    }

    let tmpList = [...appDataStore.appInfo.group_list];
    let tmpSrcList = tmpList.map((x, index) => {
      return {
        id: x.ID,
        title: x.registration_group_short_name,
        description: x.registration_group_name,
        price: x.registration_group_fee,
        checked: false,
        discount_available: x.discount_available,
        open: x.open_close,
        max_registrations: x.max_registrations,
        current_registrations: x.current_registrations
      };
    });

    // const saved_user_info = localStorage.getItem("camper_user_info");
    // if (saved_user_info != null) {
    //   let tmpObj = JSON.parse(saved_user_info);
    //   setHomePhone(tmpObj.p_home_phone);
    //   setCellPhone(tmpObj.p_cell_phone);
    //   setAHomePhone(tmpObj.a_home_phone);
    //   setACellPhone(tmpObj.a_cell_phone);
    //   setContactPhone(tmpObj.c_cell_phone);
    //   setAContactPhone(tmpObj.a_c_cell_phone);
    //   let savedUserInfo = getInitData();
    //   setUserData({
    //     ...userData,
    //     ...savedUserInfo,
    //   });
    // }


    let tmpRegList = [...appDataStore.appInfo.registration_main_list];
    tmpRegList = tmpRegList.map((x) => {
      let tmpZipStr = x.zipcodes_allowed
      let tmpZipList = []
      if (tmpZipStr != null && tmpZipStr.trim().length != 0) {
        tmpZipList = tmpZipStr.split(",")
        tmpZipList = tmpZipList.map((y) => {
          return y.trim()
        })
      }
      return {
        ...x, checked: false, zip_code_list: tmpZipList
      }
    })

    let tmpWaiverList = [...appDataStore.appInfo.waiver_list];
    tmpWaiverList = tmpWaiverList.map((x) => {
      return {
        ...x, checked: false, first_name: "", last_name: "", allow_no_checked: false
      }
    })

    setUserData({
      ...userData,
      tripList: [...tmpSrcList],
      regList: [...tmpRegList],
      waiverList: [...tmpWaiverList],
      disCountInfo: [...appDataStore.appInfo.discount_codes],
    });
  };
  const next = () => {
    if (current == 0) {
      if (!validateOneFields()) return;
      saveLocalData(current + 1);
      setCurrent(current + 1);
      window.scrollTo(0, 0);
    } else if (current == 1) {
      if (!validateTwoFields()) return;
      calcFee();
      setCurrent(current + 1);
      window.scrollTo(0, 0);
    } else if (current == 2) {
      if (!validateThreeFields()) return;
      onPurchase();
    }
  };
  const calcFee = () => {
    let src_total_amount = 0;
    let total_amount = 0;
    let total_credit = 0;
    let total_debit = 0;
    let total_echeck = 0;
    if (userData.camper_reg_checked) {
      userData.camperInfo.map((camper, key) => {
        src_total_amount += parseFloat(camper.real_fee);
      });
    }

    userData.camperInfo.map((camper, key) => {
      userData.tripList.map((x, index) => {
        if (userData.camperInfo[key].sel_trip.includes(x.id)) {
          src_total_amount += parseFloat(x.price);
        }
      });
    });

    total_amount = src_total_amount;


    let processing_credit_fee = 0;
    let processing_debit_fee = 0;
    if (isStax) {
      if (curDomain.includes("yaccamp")) {
        processing_credit_fee = parseFloat(total_amount * 0.032).toFixed(2);
        processing_debit_fee = parseFloat(total_amount * 0.16).toFixed(2);
      } else {
        processing_credit_fee = parseFloat(total_amount * 0.0365).toFixed(2);
        processing_debit_fee = parseFloat(total_amount * 0.03).toFixed(2);
      }
    } else {
      processing_credit_fee = parseFloat(total_amount * 0.03).toFixed(2);
      processing_debit_fee = parseFloat(total_amount * 0.03).toFixed(2);
    }


    let processing_echeck_fee = 2.0;

    if (!isStax) {
      if (parseFloat(src_total_amount) != 0) {
        if (processing_credit_fee < 2.0) {
          processing_credit_fee = 2.0;
        }
        if (processing_debit_fee < 2.0) {
          processing_debit_fee = 2.0;
        }
        if (processing_echeck_fee < 2.0) {
          processing_echeck_fee = 2.0;
        }
      }
    }


    total_credit = parseFloat(
      parseFloat(total_amount) + parseFloat(processing_credit_fee)
    ).toFixed(2);
    total_debit = parseFloat(
      parseFloat(total_amount) + parseFloat(processing_debit_fee)
    ).toFixed(2);
    total_echeck = parseFloat(
      parseFloat(total_amount) + parseFloat(processing_echeck_fee)
    ).toFixed(2);

    let tmpObj = { ...userData };
    tmpObj = {
      ...tmpObj,
      total_amount: parseFloat(total_amount).toFixed(2),
      src_total_amount: parseFloat(src_total_amount).toFixed(2),

      processing_credit_fee: processing_credit_fee,
      processing_debit_fee: processing_debit_fee,
      processing_echeck_fee: processing_echeck_fee,

      total_credit: parseFloat(total_credit).toFixed(2),
      total_debit: parseFloat(total_debit).toFixed(2),
      total_echeck: parseFloat(total_echeck).toFixed(2),

      total_checkout: parseFloat(total_credit).toFixed(2),
      processing_fee: processing_credit_fee,
    };
    setUserData(tmpObj);
    localStorage.setItem("camper_user_info", JSON.stringify(tmpObj));
    localStorage.setItem("camper_step", 2);
  };
  const saveLocalData = (cur) => {
    localStorage.setItem("camper_user_info", JSON.stringify(userData));
    localStorage.setItem("camper_step", cur);
  };
  const prev = () => {
    if (current == 0) {
      history.goBack();
    } else {
      setCurrent(current - 1);
    }
  };

  const onPurchase = (
    payment_method = "",
    transaction_id = "",
    entered_by = "",
    authorization_code = "",
    location_id = ""
  ) => {
    let param = { ...userData };
    param = {
      ...param,
      payment_method,
      transaction_id,
      entered_by,
      authorization_code,
      location_id,
      recordList,
      reg_type: regType,
      registration_id: appInfo.registration_info?.ID,
      registration_name: appInfo.registration_info?.registration_name,
      yaccamp: curDomain.includes("yaccamp") ? "1" : "0",
      isStax,
      userData: userData
    };
    setIsBusy(true);
    apiRegisterUser(param)
      .then((res) => {
        setIsBusy(false);
        if (res.result) {
          message.success("Welcome " + appInfo?.registration_info?.registration_name + "!");
          localStorage.removeItem("camper_user_info");
          localStorage.removeItem("camper_step");
          localStorage.removeItem("camper_registration_type");
          setTimeout(() => {
            history.replace(ROUTE_WELCOME);
          }, 300);
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };

  const onPaymentFail = (payment_method = "", err_res, api_name) => {
    setIsBusy(true);
    let param = { ...userData };
    param = {
      ...param,
      payment_method,
      err_res,
      api_name
    };
    setIsBusy(true);
    apiRegisterFailedPayment(param)
      .then((res) => {
        setIsBusy(false);
        if (res.result) {
        } else {
          message.error(res.msg);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        message.error("Something went wrong");
      });
  };

  const steps = [
    {
      title: "Participant Info",
      subtitle: "Please enter participant info below.",
      content: "",
    },
    {
      title: "Participant Fees",
      subtitle: "Please select the fee(s) that apply to each participant.",
      content: "",
    },
    {
      title: "Checkout",
      subtitle:
        "Please reivew your registration fees and select a payment method.",
      content: "",
    },
  ];

  const validateOneFields = () => {
    var errorList = Array();

    let tmpList = [...userData.camperInfo];
    let tmpUIlist = [...uiList]
    let participantUiList = tmpUIlist.filter((x) => x.group_type == "1")
    tmpList.map((x, index) => {
      participantUiList.map((uiItem, uIndex) => {
        if (uiItem.is_required == "true" && uiItem.ui_type != "5") {
          errorList = isEmpty(x, uiItem.reg_column_name, errorList, index);
        }
      })
    });
    let otherUIList = tmpUIlist.filter((x) => x.group_type != "1")
    otherUIList.map((uiItem, uIndex) => {
      if (uiItem.is_required == "true") {
        errorList = isEmpty(userData, uiItem.reg_column_name, errorList);
      }
    })
    let includeFile = participantUiList.filter((x) => x.ui_type == "5" && x.is_required == "true" && x.is_filepicker == "true").length > 0 ? true : false;
    if (includeFile) {
      let tmpRecordList = [...recordList];
      if (tmpList.length != tmpRecordList.length) {
        errorList.push(tmpRecordList.length + "record");
      }
      tmpRecordList.map((x, index) => {
        errorList = isEmptyOneField(x, "record", errorList, index);
      });
    }


    setErrorField([...errorList]);

    return errorList.length == 0;
  };
  const validateTwoFields = () => {
    let valid = true;
    let errorList = Array();
    if (regType == "true") {
      for (let i = 0; i < userData.regList.length; i++) {
        if (userData.regList[i].checked) {
          valid = true;
          break;
        } else {
          valid = false;
        }
      }
    } else {
      for (let i = 0; i < userData.camperInfo.length; i++) {
        if (userData.camperInfo[i].sel_trip) {
          valid = true;
          break;
        } else {
          valid = false;
        }
      }
    }

    if (!valid && regType == "true") {
      message.error(
        "You must choose a registration to proceed"
      );
      return valid
    }
    if (regType == "true") {
      let tmpList = [...userData.waiverList];
      for (let index = 0; index < tmpList.length; index++) {
        let tmpWaiver = tmpList[index]
        if (tmpWaiver.allow_no == "1") {
          if (!tmpWaiver.allow_no_checked) {
            errorList = isEmpty(tmpWaiver, "first_name", errorList, index);
            errorList = isEmpty(tmpWaiver, "last_name", errorList, index);
            if (!tmpWaiver.checked) {
              valid = false
              break
            }
          }
        } else {
          errorList = isEmpty(tmpWaiver, "first_name", errorList, index);
          errorList = isEmpty(tmpWaiver, "last_name", errorList, index);
          if (!tmpWaiver.checked) {
            valid = false
            break
          }
        }
      }
    }
    setErrorField(errorList)
    if (!valid && regType == "true") {
      message.error(
        "You must confirm all electronic signature fields before proceeding"
      );
    } else if (!valid && regType == "false") {
      message.error(
        "You must select extra programs"
      );
    }
    return valid && errorField.length == 0;
  };
  const validateThreeFields = () => {
    if (!userData.isPayment) {
      message.error("Payment Not Completed");
    }
    return userData.isPayment;
  };
  const onBack = () => {
    if (current == 0) {
      history.push(ROOT);
    } else {
      setCurrent(current - 1);
    }
  };

  return (
    <div className="register-page">
      <Spin spinning={isBusy}>
        <CommonDivWidget>
          <div style={{ position: "absolute" }}>
            <img
              src="/assets/images/ic_back.png"
              style={{ width: 22, margin: "5px 0px", cursor: "pointer" }}
              onClick={() => {
                onBack();
              }}
            />
          </div>
          <div className="title">{appInfo.registration_info?.registration_name}</div>
          <div className="title">{steps[current].title}</div>
          <div className="sub-title">{steps[current].subtitle}</div>
          <Steps current={current} className="step">
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>
          <div className="steps-content">
            {current == 0 && (
              <StepInfo
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                homePhone={homePhone}
                setHomePhone={setHomePhone}
                cellPhone={cellPhone}
                setCellPhone={setCellPhone}
                contactPhone={contactPhone}
                setContactPhone={setContactPhone}
                acontactPhone={acontactPhone}
                setAContactPhone={setAContactPhone}
                aCellPhone={aCellPhone}
                setACellPhone={setACellPhone}
                aHomePhone={aHomePhone}
                setAHomePhone={setAHomePhone}
                onNext={next}
                recordList={recordList}
                setRecordList={setRecordList}
                regType={regType}
                curDomain={curDomain}
                uiList={uiList}
              />
            )}
            {current == 1 && (
              <StepFee
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
                regType={regType}
                curDomain={curDomain}
                uiList={uiList}
              />
            )}
            {current == 2 && (
              isStax ? (curDomain.includes("yaccamp") ? <StepCheckoutStaxMulti
                appInfo={appInfo}
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
                onRegister={onPurchase}
                onPaymentFail={onPaymentFail}
                recordList={recordList}
                regType={regType}
                curDomain={curDomain}
              /> : <StepCheckoutStax
                appInfo={appInfo}
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
                onRegister={onPurchase}
                onPaymentFail={onPaymentFail}
                recordList={recordList}
                regType={regType}
                curDomain={curDomain}
              />) : <StepCheckoutForte
                appInfo={appInfo}
                userData={userData}
                setUserData={setUserData}
                errorField={errorField}
                setErrorField={setErrorField}
                onNext={next}
                onRegister={onPurchase}
                onPaymentFail={onPaymentFail}
                recordList={recordList}
                regType={regType}
                curDomain={curDomain}
              />
            )}
          </div>
          <div className="steps-action">
            {errorField.length != 0 && (
              <div className="valid-rect">
                <span>Correct errors in red above before proceeding</span>
              </div>
            )}
            {!feeValid && (
              <div className="valid-rect">
                <span>Please select trip</span>
              </div>
            )}
            {current == 0 && (
              <div>
                <Row justify="center">
                  <CommonButton
                    customClass="register-next-button"
                    onClick={() => next()}
                  >
                    Next
                  </CommonButton>
                </Row>
                <Row justify="center" style={{ marginTop: 10 }}>
                  <CommonButton
                    customClass="register-cancel-button"
                    onClick={() => prev()}
                  >
                    Cancel
                  </CommonButton>
                </Row>
              </div>
            )}
            {current == 1 && (
              <Row>
                <Col flex="180" style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-prev-button"
                    onClick={() => prev()}
                  >
                    Previous
                  </CommonButton>
                </Col>
                <Col flex="auto" style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-next-button"
                    onClick={() => next()}
                  >
                    Next
                  </CommonButton>
                </Col>
              </Row>
            )}
            {current == 2 && (
              <Row justify="center">
                <Col style={{ marginTop: 25 }}>
                  <CommonButton
                    customClass="register-prev-button"
                    onClick={() => prev()}
                  >
                    Previous
                  </CommonButton>
                </Col>
              </Row>
            )}
          </div>
          <BackTop />
        </CommonDivWidget>
      </Spin>
    </div>
  );
}

export default RegisterPage;
